import React from 'react';
import logo from './coffee.jpg';
import './App.css';
import ReactPlayer from 'react-player';

function App() {
  return (
    <div className="App" style={{backgroundImage: `url(${logo})`, backgroundSize: "cover", backgroundPosition: "bottom"}}>
	  <ReactPlayer url="https://www.youtube.com/watch?v=fEvM-OUbaKs" />
    </div>
  );
}

export default App;
